// Adjust the path if needed

import addQueryParams from "../../../utils/addQueryParams";
import request from "../../../utils/request";

export async function upsertUserDetails(
  email,
  aadObjectId,
  body,
  primaryEmail
) {
  if (!email && !aadObjectId) {
    throw new Error("Missing required parameters.");
  }

  const options = {
    payload: {
      email: email,
      aadObjectId,
      primaryEmail,
      ...body,
    },
  };

  const querys = {
    env: "qc",
  };

  const BASE_DOMAIN = process.env.REACT_APP_USERDB_BASE_DOMAIN;
  const url = `${BASE_DOMAIN}/merlinassist/api/zycuschat/common?data=upsertUserEnv`;
  const fullUrl = addQueryParams(url, querys);
  const envQuery = process.env.REACT_APP_USERDB_BASE_DOMAIN_ENV;
  try {
    const response = await request("POST", fullUrl, options, envQuery);
    return response;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
}
