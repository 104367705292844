import Cookies from "js-cookie";

const setCookie = (name, value, options, callback) => {
  const res = Cookies.set(name, value, options);
  if (res && callback) {
    callback();
  }
};

const getCookie = (name) => {
  return Cookies.get(name);
};

const removeCookie = (name, options) => {
  // Make options optional
  Cookies.remove(name, { ...options, domain: "" });
  Cookies.remove(name, options);
};

export { setCookie, getCookie, removeCookie };
