import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import ErrorBoundaryFallback from './ErrorBoundary';


const rootElement = document.getElementById('root') ;

// Use createRoot to render your application
const root = createRoot(rootElement)

root.render(<CookiesProvider>
          <ErrorBoundaryFallback>
           <App/>
          </ErrorBoundaryFallback>
    </CookiesProvider>
);


