function addQueryParam(url, paramName) {
  const rpString = "rp";
  const paramKey = "env";
  const paramValue = "qc";
  if (url.includes(rpString) && paramName && paramName === "QC") {
    const urlObj = new URL(url);
    urlObj.searchParams.set(paramKey, paramValue);
    return urlObj.toString();
  } else if (url.includes(rpString) && paramName && paramName === "USStage") {
    return url.replace("rp", "staging");
  } else if (url.includes(rpString) && paramName && paramName === "AUSStage") {
    return url.replace("rp", "ausyut");
  } else if (url.includes(rpString) && paramName && paramName === "USProd") {
    return url.replace("-rp", "");
  } else if (url.includes(rpString) && paramName && paramName === "EUProd") {
    return url.replace("rp", "eu");
  } else if (url.includes(rpString) && paramName && paramName === "SGprod") {
    return url.replace("rp", "sg");
  } else if (url.includes(rpString) && paramName && paramName === "AUSProd") {
    return url.replace("rp", "au");
  }

  return url;
}

export { addQueryParam };
