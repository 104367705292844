import ContentLoader from "react-content-loader";
import { useUserContext } from "../../../../context/userGetContext";
import { getLoader } from "../../../../utils/common";

const Loader = ({ message }) => (
  <div>
    <span>{message}</span>
    <div style={{ width: "90%", padding: "20px" }}>
      <ContentLoader
        backgroundColor="#bdbdbd"
        foregroundColor="#ecebeb"
        viewBox="0 0 400 160"
      >
        {/* Your loader rectangles and circle */}
      </ContentLoader>
    </div>
  </div>
);

const withLoading = (Component) =>
  function WithLoadingComponent({ loading, message, ...props }) {
    const context = useUserContext();
    if (loading) {
      return <Loader message={getLoader(context?.theme)} />;
    }
    return <Component {...props} />;
  };

export default withLoading;
