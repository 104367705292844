import axios from "axios";
import { addQueryParam } from "./addEnvQuery";

async function request(method = "GET", url, options, env) {
  const fullUrl = addQueryParam(url, env);
  try {
    const response = await axios({
      method: method,
      url: fullUrl,
      headers: options?.headers || { "Content-Type": "application/json" },
      data: JSON.stringify(options?.payload),
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
}

export default request;
