const keys = {
  TEAMS_CONTEXT_KEY: "teamscontext",
  ADD_ACCOUNT: "addAccount",
  URL: "url",
  AUTHENTICATION_ID: "authenticationId",
  ENV: "env",
  SAAS_COMMON_BASE_TOKEN_ID: "SAAS_COMMON_BASE_TOKEN_ID",
};

export { keys };
