import React from "react";
import withLazyLoad from "./withLazyLoad";
import { isUserDBSelectionEnabled } from "../utils/common";

const Collaboration = withLazyLoad(() => import("../pages/Collaboration"));
const RootNavigator = withLazyLoad(() => import("../pages/ZycusChat"));
const RootNavigatorMDB = withLazyLoad(() => import("../pages/ZycusChatV2"));

//const userdbselection = process.env.REACT_APP_USERDB_SELECTION || false;
const userdbselectionBool = isUserDBSelectionEnabled(); // userdbselection === "true";

const routes = [
  {
    component: userdbselectionBool ? <RootNavigatorMDB /> : <RootNavigator />,
    path: "/",
    title: "RootNavigator",
  },
  {
    component: <Collaboration />,
    path: "/",
    title: "Collaboration",
  },
  {
    component: userdbselectionBool ? <RootNavigatorMDB /> : <RootNavigator />,
    path: "*",
    title: "RootNavigator",
  },
];

export default routes;
