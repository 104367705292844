const STRINGS_EN = {
  CONTEXT: "Please wait while we fetch Teams context....",
  HANGON: "Please hang on....we are fetching some details to log you in",
  NO_CONTEXT: "Please wait while we are making things ready for you...!!!",
  LOADING: "Almost there....Please wait!!!",
  ERROR: "Something went wrong...Please try refreshing!!!",
  NOENV:
    "We are unable to find your Zycus account. Kindly reach out to your IT Administration team for assistance.",
  NO_TENANT:
    "You are not assigned with any tenant. Please contact your company administrator.",
  MST_BRAND_LOGIN: "Zycus Login",
  SUBMIT: "Submit",
  ADD_ACCOUNT: "Add Account",
  MST_REQUEST_ERROR: "An error occurred. Please try again.",
  MST_NOTIFICATIONS_TITLE: "MST_Notifications",
  MST_PROFILE_TITLE: "MST_Profile",
  MST_SETTINGS_TITLE: "MST_IMANAGE_Settings",
  MST_MA_Intro_message: "How can Merlin Assist help you today?",
  MST_Immediate_Actions: "Immediate Actions",
  MST_Viewall_Actions: "View all Actions",
  MST_Start_Chat: "Start Chat",
  MST_Immediate_Actions_Not_LoggedInMessage:
    "Want to see what's tailored just for you?",
  MST_Login_to_check_your_personalized_actions:
    "Login to check your personalized actions!",
  MST_Login: "Login",
  MST_Email: "Email",
  MST_Welcome: "Welcome",
  MST_Password: "Password",
  MST_Password_placeholder: "Type your password",
  MST_Forgot_Password: "Forgot Password",
  MST_Frequent_Actions: "Frequent Actions",
  MST_EMAIL_PLACEHOLDER_TEXT: "Type Your Email",
  MST_Approve: "Approve",
  MST_Search: "MST_Search",
  MST_Next: "Next",
  MST_Rename: "Rename",
  MST_Delete: "Delete",
  MST_SUBMIT: "Submit",
  MST_EMAIL_ADDRESS_INVALID: "Please enter a valid email address.",
  MST_LOGIN_PAGE_URL: "Login Page Url",
  MST_TYPE_YOUR_URL: "Type Your Url",
  MST_Not_LoggedIn_Message:
    "Looks like you aren't connected to Zycus application.",
  MST_Login_Message: "Let's quickly log you in...",
  MST_Expectation_Failed: "Expectation Failed. Please try again later.",
  MST_Login_Failed: "Login failed. Please try again.",
  MST_Network_Error:
    "Network error. Please check your internet connection and try again.",
  MST_Figure_Out_Message: "Can't figure out what to do?",
  MST_Zycus_Account_Not_Found: "Unable to find your Zycus Account",
  MST_You_are_logged_in_as: "You are logged in as:",
  MST_Logout: "Sign Out",
  MST_Switch: "Switch",
  MST_Add_another_account: "Add another account",
  MST_Add_account: "Login to Zycus",
  MST_Profile: "Profile",
  MST_Invalid_Captcha: "Incorrect Captcha",
  MST_Something_went_wrong_please_try_again:
    "Something went wrong please try again",
  MST_Home: "Home",
  MST_History: "History",
  MST_Notification: "Notification",
  MST_IMANAGE_Settings: "Settings",
  MST_Password_Required: "Password is required.",
  MST_Approve_success: "you have successfully approved the requistion",
  MST_Immediate_Approve_success: "Successfully approved",
  MST_Required_Field: "This Field is required !",
  MST_You: "You",
  MST_EMAIL_REGEX: "/^[^s@]+@[^s@]+.[^s@]+$/",
  MST_Use_Another_Account: "Use Another Account",
  MST_unable_to_find_zycus_acc: "Unable to find your Zycus account",
  MST_MA_VERSION_2: "2",
  MST_first_time_user_send_teams:
    "Looks like you aren't logged in to MS-Teams. Please login to continue.",
};

export default STRINGS_EN;
