import request from "./request";

const triggerOperation = async (
  tableName,
  action,
  entity,
  key,
  azureAccountName
) => {
  if (!tableName || !action || (!entity?.aadObjectId && !entity?.email)) return;

  const tableBaseUrl = process.env.REACT_APP_CRUD_OP_TABLE_BASE_URL;
  const tablePath = process.env.REACT_APP_CRUD_OP_TABLE_PATH;
  const securityCode = process.env.REACT_APP_CRUD_OP_TABLE_SECURITY_CODE;
  const accessKey = process.env.REACT_APP_MAIN_STORAGE_ACC_ACCESS_KEY;
  const accountName = process.env.REACT_APP_MAINSTORAGEACCNAME;

  if (
    !tableBaseUrl ||
    !tablePath ||
    !securityCode ||
    !accessKey ||
    !accountName
  ) {
    return;
  }

  const keys = {
    partitionKey: entity?.aadObjectId,
    rowKey: entity?.aadObjectId,
  };
  const options = {
    payload: {
      accessKey: key || accessKey,
      table: tableName,
      operation: action,
      accountName: azureAccountName || accountName,
      entity: { ...entity, ...keys },
    },
  };
  const url = `https://${tableBaseUrl}/${tablePath}?code=${securityCode}`;

  try {
    const response = await request("POST", url, options);
    return response;
  } catch (error) {
    return error;
  }
};

export { triggerOperation };
