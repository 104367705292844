import React from 'react';
import { ErrorBoundary  } from 'react-error-boundary';
import FallbackComponent from './FallbackComponent';


function ErrorBoundaryFallback({ children }){
  return(
  <ErrorBoundary FallbackComponent={<FallbackComponent/>}>
   {children}
 </ErrorBoundary>
 )
};

export default ErrorBoundaryFallback;